import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";

import { Provider } from "react-redux";
import setAuthorizationToken from "./utils/TokenInterceptor";
//import store from "./store/store";
import EmailVerify from "views/examples/EmailVerify";

import persist from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
const persistStore = persist();

//git push https://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@github.com/Octo5-Holdings-Limited/admin-portal.git
setAuthorizationToken();
ReactDOM.render(
  <Provider store={persistStore.store}>
    <PersistGate loading={null} persistor={persistStore.persistor}>
      <BrowserRouter>
        <Switch>
          <Route
            path={"/auth" + "/user-email/:uid/:email"}
            component={EmailVerify}
            //key={key}
          />
          <Route path='/auth' render={(props) => <AuthLayout {...props} />} />
          <Route path='/admin' render={(props) => <AdminLayout {...props} />} />

          <Redirect from='/' to='/admin/index' />
        </Switch>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
