const api = Object.freeze({
  //   // LIVE
  //     REACT_APP_FIREBASE_KEY:"AIzaSyANAKdIUm0EZJkKzcCBpq1ROzMlRbr0mN4",
  //     REACT_APP_FIREBASE_DOMAIN:"stow-62251.firebaseapp.com",
  //     REACT_APP_FIREBASE_DATABASE:"https://stow-62251.firebaseio.com",
  //     REACT_APP_FIREBASE_PROJECT_ID:"stow-62251",
  //     REACT_APP_FIREBASE_STORAGE_BUCKET:"stow-62251.appspot.com",
  //     REACT_APP_FIREBASE_SENDER_ID:631799171633,
  //     REACT_APP_FIREBASE_APP_ID:"1:631799171633:web:7a3ca2be2e4199551b2310",
  //     REACT_APP_FIREBASE_MEASUREMENT_ID:"G-QFTT0893VK",

  // DEV
  REACT_APP_FIREBASE_KEY:"AIzaSyC0Ud9Xl5mHIrxzDT8ZAjS4DytVbEZuf_Y",
  REACT_APP_FIREBASE_DOMAIN: "octo5stowdev.firebaseapp.com",
  REACT_APP_FIREBASE_DATABASE: "https://octo5stowdev-default-rtdb.firebaseio.com",
  REACT_APP_FIREBASE_PROJECT_ID: "octo5stowdev",
  REACT_APP_FIREBASE_STORAGE_BUCKET: "octo5stowdev.appspot.com",
  REACT_APP_FIREBASE_SENDER_ID: 11654968532,
  REACT_APP_FIREBASE_APP_ID: "1:11654968532:web:e6d91c468ba51e11ecb741",
  REACT_APP_FIREBASE_MEASUREMENT_ID: "G-QFTT0893VK",

  //CONSTANT
  API_URL: "http://127.0.0.1:3000",
  SEND_GRID_API_KEY: "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",
  SEND_GRID_TEMPLATE_ONE_ID: "d-a61360f375504de090d17b73d1116ff3",
  SEND_GRID_TEMPLATE_VERIFY_EMAIL_ID: "d-0359561ef1df4a04825c8bf692854170",
  SENDGRID_POST_URL: "https://api.sendgrid.com/v3/mail/send",
});

export default api;
